import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../service/loader.service";
import * as i4 from "../../../service/session/sessionStorage.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, loaderService, sessionStorageService) {
        this.authConfig = authConfig;
        this.http = http;
        this.loaderService = loaderService;
        this.sessionStorageService = sessionStorageService;
        this.config = AUTH_CONFIG;
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
    }
    AuthenticationService.prototype.checkPhoneAndSendPin = function (phone) {
        var domain = window.location.hostname;
        return this.http.post(this.config.checkPhoneAndSendPin, { phone: phone, domain: domain })
            .pipe(map(function (res) {
            // if (environment.frontEndTest) {
            //     res.promoCode = true;
            // }
            return res;
        }));
    };
    AuthenticationService.prototype.login = function (phone, pin, promoCode) {
        var _this = this;
        if (promoCode === void 0) { promoCode = null; }
        this.loaderService.show();
        var domain = window.location.hostname;
        var body;
        if (promoCode) {
            body = { phone: phone, pin: pin, domain: domain, promoCode: promoCode };
        }
        else {
            body = { phone: phone, pin: pin, domain: domain };
        }
        return this.http.post(this.config.login, body)
            .pipe(map(function (res) {
            // if (environment.frontEndTest) {
            //     res = {
            //         status: true,
            //         token: 'dkdkddkdk',
            //         appLink: 'http://192.168.0.101:4202',
            //         message: "djdjdj"
            //     }
            // }
            if (res.status) {
                _this.userLoggedIn.emit(true);
                _this.sessionStorageService.setUserToken(res.token);
                _this.sessionStorageService.setAppLink(res.appLink);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.userLoggedIn.emit(false);
                this.sessionStorageService.cleanStorage();
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.SessionStorageService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
