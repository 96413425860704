import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {GestureConfig, MatDatepickerModule, MatNativeDateModule} from "@angular/material";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './service/jwt.interceptor';
import {AUTH_CONFIG} from './config/api';
import {SharedModule} from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthModule} from './modules/auth/auth.module';
import {ChatQuizModule} from "./pages/pages.module";
import {MatSliderModule} from '@angular/material/slider';
import { NgxCurrencyModule } from "ngx-currency";

// Import your library
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {registerLocaleData} from "@angular/common";
import localeMX from "@angular/common/locales/es-MX";
import localeMXExtra from "@angular/common/locales/extra/es-MX";
import localeEn from "@angular/common/locales/en";
import localeEnExtra from "@angular/common/locales/extra/en";

registerLocaleData(localeMX, 'es-MX', localeMXExtra);
registerLocaleData(localeEn, 'en', localeEnExtra);

@NgModule({
  declarations: [
    AppComponent,

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        AuthModule.forRoot(AUTH_CONFIG, {currentUserRoles: '/api/session/roles'}),
        ChatQuizModule,
        MatSliderModule,
        FormsModule,
        NgxCurrencyModule,
        MatNativeDateModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MatDatepickerModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },


  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
