import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HOME_CONFIG} from "../config/api";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    private config = HOME_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {
    }

    getPageInfo(promoCode: string) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {promoCode})
          .pipe(map((res: any) => {
              // if (environment.frontEndTest) {
              //     res.promoCode = true;
              // }
              return res;
          }));
    }

    getUserStatus(email: string, phone: string, promoCode: string) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getUserStatus, {email, phone, promoCode})
          .pipe(map((res: any) => {
              // if (environment.frontEndTest) {
              //     res.promoCode = true;
              // }
              return res;
          }));
    }

    payWithCashApp(email: string, phone: string, promoCode: string, keywords: string, token: string) {
      this.loaderService.show();
      return this.http.post<any>(this.config.payWithCashApp, {email, phone, promoCode, keywords, token})
        .pipe(map((res: any) => {
          // if (environment.frontEndTest) {
          //     res.promoCode = true;
          // }
          return res;
        }));
    }
}
