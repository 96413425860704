<div class="login">
    <div class="login__inner">
        <div class="login__content">
            <div class="login__header">
                <img class="logo" [src]="partnerLogo" alt="">
            </div>
            <app-balance-heading [entries]="0" [send]="0"></app-balance-heading>

            <router-outlet></router-outlet>
        </div>
    </div>
</div>
