import {COUNTRIES_SHORT} from "./countries.config";

export const COUNTRIES_LANG = {
  en: ['default'],
  es: [COUNTRIES_SHORT.mexico],
  fr: [COUNTRIES_SHORT.france]
};

export const COUNTRIES_LANG_AVAILABLE = [
  'en',
  'es',
  'fr'
];
