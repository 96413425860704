import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    DateAdapter,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";
import {DemoMaterialModule} from "../material.module";
import {NgxDateRangeModule} from "ngx-daterange";
import {CustomDateAdapter} from "../adapters/custom-date-adapter";

@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        HomeComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        NgxCurrencyModule,
        DemoMaterialModule,
      NgxDateRangeModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
        {provide: DateAdapter, useClass: CustomDateAdapter }
    ],
    exports: [
        LoginComponent
    ],
    entryComponents: [
        LoginComponent
    ]
})
export class ChatQuizModule { }
