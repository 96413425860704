import { COUNTRIES_DOMAINS } from "./country.config";
import * as i0 from "@angular/core";
var CountryService = /** @class */ (function () {
    function CountryService() {
    }
    CountryService.prototype.detectCountry = function () {
        var host = window.location.host;
        // const host = 'mex.dkdk.com';
        // const host = 'fr.dkdk.com';
        var subdomain = host.split('.')[0];
        //const subdomain = 'mx';
        return COUNTRIES_DOMAINS[subdomain] ? COUNTRIES_DOMAINS[subdomain] : COUNTRIES_DOMAINS.default;
    };
    CountryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountryService_Factory() { return new CountryService(); }, token: CountryService, providedIn: "root" });
    return CountryService;
}());
export { CountryService };
