/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./popup-wrapper.component";
var styles_PopupWrapperComponent = [i0.styles];
var RenderType_PopupWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupWrapperComponent, data: {} });
export { RenderType_PopupWrapperComponent as RenderType_PopupWrapperComponent };
function View_PopupWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "popup-wrapper__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["class", "popup-wrapper__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "popup-wrapper__scrolled"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_PopupWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "popup-wrapper"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupWrapperComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.show; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 0, 0, currVal_0); }); }
export function View_PopupWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-wrapper", [], null, null, null, View_PopupWrapperComponent_0, RenderType_PopupWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i3.PopupWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupWrapperComponentNgFactory = i1.ɵccf("app-popup-wrapper", i3.PopupWrapperComponent, View_PopupWrapperComponent_Host_0, { show: "show" }, { emitClose: "onClose" }, ["*"]);
export { PopupWrapperComponentNgFactory as PopupWrapperComponentNgFactory };
