import * as tslib_1 from "tslib";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
import * as i2 from "../modules/language/language.service";
import * as i3 from "@angular/common/http";
var CashappPayService = /** @class */ (function () {
    function CashappPayService(loaderService, languageService, http) {
        this.loaderService = loaderService;
        this.languageService = languageService;
        this.http = http;
        this.options = {
            redirectURL: window.location.href,
            referenceId: 'my-distinct-reference-id',
        };
        this.buttonOptions = {
            shape: 'semiround',
            width: 'full',
        };
        this.scriptId = 'cashAppScript';
        this.paymentResult = new BehaviorSubject(null);
    }
    CashappPayService.prototype.createCashAppPayButton = function (cashappInfo, amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var script;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                script = document.createElement('script');
                script.src = cashappInfo.mode === 'sandbox' ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js";
                script.setAttribute('id', this.scriptId);
                script.onload = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
                                this.req = this.payments.paymentRequest({
                                    countryCode: 'US',
                                    currencyCode: 'USD',
                                    total: {
                                        amount: String(amount),
                                        label: this.languageService.getString('total'),
                                    },
                                });
                                return [4 /*yield*/, this.showCashAppPayButton(amount)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); };
                document.body.appendChild(script);
                return [2 /*return*/];
            });
        });
    };
    CashappPayService.prototype.showCashAppPayButton = function (amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.payments) return [3 /*break*/, 3];
                        this.req = this.payments.paymentRequest({
                            countryCode: 'US',
                            currencyCode: 'USD',
                            total: {
                                amount: String(amount),
                                label: this.languageService.getString('total'),
                            },
                        });
                        if (!!this.cashAppPay) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.payments.cashAppPay(this.req, this.options)];
                    case 1:
                        _a.cashAppPay = _b.sent();
                        this.cashAppPay.addEventListener('ontokenization', function (event) {
                            var tokenResult = event.detail.tokenResult;
                            _this.paymentResult.next(tokenResult);
                            _this.destroyCashApp();
                            // if (tokenResult.status === 'OK') {
                            //     cashAppPay.destroy();
                            // }
                        });
                        return [4 /*yield*/, this.cashAppPay.attach('#cash-app-pay', this.buttonOptions)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CashappPayService.prototype.isCashAppPayScripExist = function () {
        return document.querySelector("#" + this.scriptId);
    };
    CashappPayService.prototype.destroyCashApp = function () {
        if (this.cashAppPay) {
            this.cashAppPay.destroy();
            this.cashAppPay = null;
        }
    };
    CashappPayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashappPayService_Factory() { return new CashappPayService(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i2.LanguageService), i0.ɵɵinject(i3.HttpClient)); }, token: CashappPayService, providedIn: "root" });
    return CashappPayService;
}());
export { CashappPayService };
