import { map } from 'rxjs/operators';
import { HOME_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var HomeService = /** @class */ (function () {
    function HomeService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = HOME_CONFIG;
    }
    HomeService.prototype.getPageInfo = function (promoCode) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { promoCode: promoCode })
            .pipe(map(function (res) {
            // if (environment.frontEndTest) {
            //     res.promoCode = true;
            // }
            return res;
        }));
    };
    HomeService.prototype.getUserStatus = function (email, phone, promoCode) {
        this.loaderService.show();
        return this.http.post(this.config.getUserStatus, { email: email, phone: phone, promoCode: promoCode })
            .pipe(map(function (res) {
            // if (environment.frontEndTest) {
            //     res.promoCode = true;
            // }
            return res;
        }));
    };
    HomeService.prototype.payWithCashApp = function (email, phone, promoCode, keywords, token) {
        this.loaderService.show();
        return this.http.post(this.config.payWithCashApp, { email: email, phone: phone, promoCode: promoCode, keywords: keywords, token: token })
            .pipe(map(function (res) {
            // if (environment.frontEndTest) {
            //     res.promoCode = true;
            // }
            return res;
        }));
    };
    HomeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeService_Factory() { return new HomeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: HomeService, providedIn: "root" });
    return HomeService;
}());
export { HomeService };
