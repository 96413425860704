import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class HomeComponent implements OnInit {
    partnerLogo = '../assets/images/logo.png';

    constructor() {}
    ngOnInit() {}
}
