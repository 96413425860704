import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private storage = sessionStorage;
    private userTokenKey = 'token';
    private appLinkKey = 'appLink';

    constructor(private http: HttpClient) {}

    setUserToken(val) {
        this.storage.setItem(this.userTokenKey, val);
    }

    getUserToken() {
        return this.storage.getItem(this.userTokenKey);
    }

    removeUserToken(): void {
        this.storage.removeItem(this.userTokenKey);
    }

    setAppLink(val) {
        this.storage.setItem(this.appLinkKey, val);
    }

    getAppLink() {
        return this.storage.getItem(this.appLinkKey);
    }

    removeAppLink(): void {
        this.storage.removeItem(this.appLinkKey);
    }

    cleanStorage() {
        this.removeUserToken();
        this.removeAppLink();
    }
}
