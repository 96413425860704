import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {AuthConfig} from './auth-config';
import {AUTH_CONFIG} from '../../../config/api';
import {LoaderService} from "../../../service/loader.service";
import {SessionStorageService} from "../../../service/session/sessionStorage.service";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private config = AUTH_CONFIG;

    roles: string[];
    rolesObservable = new EventEmitter<any>();
    userLoggedIn = new EventEmitter<any>();

    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private http: HttpClient,
                private loaderService: LoaderService,
                private sessionStorageService: SessionStorageService
                ) {
    }

    checkPhoneAndSendPin(phone: string){
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.checkPhoneAndSendPin, {phone:phone, domain})
          .pipe(map((res: any) => {
              // if (environment.frontEndTest) {
              //     res.promoCode = true;
              // }
              return res;
          }));
    }

    login(phone: string, pin: string, promoCode: string = null) {
        this.loaderService.show();
        const domain = window.location.hostname;

        let body;
        if (promoCode) {
            body = {phone, pin, domain, promoCode};
        } else {
            body = {phone, pin, domain};
        }

        return this.http.post<any>(this.config.login, body)
            .pipe(map((res: any) => {
                // if (environment.frontEndTest) {
                //     res = {
                //         status: true,
                //         token: 'dkdkddkdk',
                //         appLink: 'http://192.168.0.101:4202',
                //         message: "djdjdj"
                //     }
                // }

                if (res.status) {
                    this.userLoggedIn.emit(true);
                    this.sessionStorageService.setUserToken(res.token);
                    this.sessionStorageService.setAppLink(res.appLink);
                }

                return res;
            }));
    }

    async logout() {
        this.userLoggedIn.emit(false);
        this.sessionStorageService.cleanStorage();
    }
}
