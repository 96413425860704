import {Pipe, PipeTransform} from "@angular/core";
import {LanguageService} from "./language.service";

@Pipe({
  name: 'languageTranslate',
  pure: false
})
export class LanguageTranslatePipe implements PipeTransform {
  reservedInnerVariableName = "$innerVariable";

  constructor(private languageService: LanguageService) { }
  transform(value: any, ...args: any[]): any {
    if (value) {
      let string = this.languageService.getString(value);
      if (args.length) {
        args.forEach(arg => {
          arg.forEach(argInner => {
            Object.keys(argInner).forEach(argItem => {
              if (argItem !== this.reservedInnerVariableName) {
                string = string.replace(`{{${argItem}}}`, argInner[argItem]);
              } else {
                string = this.languageService.getString(value, argInner[argItem]);
              }
            })
            // if (Object.keys(argInner)[0] !== this.reservedInnerVariableName) {
            //   string = string.replace(`{{${Object.keys(argInner)[0]}}}`, argInner[Object.keys(argInner)[0]]);
            // } else {
            //   string = this.languageService.getString(value, argInner[Object.keys(argInner)[0]]);
            // }
          });
        })
      }
      return string;
    }
    return value;
  }
}
