import * as defaultText from '../../../assets/languages/en/text.json';
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var LanguageService = /** @class */ (function () {
    function LanguageService() {
        this.activeLanguageKey = 'activeLanguage';
        this.defaultLanguage = 'en';
        this.defaultLanguageText = defaultText['default'];
        this.requestNewLanguage = new BehaviorSubject(null);
        this.languageIsUpdated = new BehaviorSubject(null);
    }
    LanguageService.prototype.changeLanguage = function (lang) {
        this.requestNewLanguage.next(lang);
    };
    LanguageService.prototype.setActiveLanguage = function (lang) {
        var newLang = lang ? lang : this.defaultLanguage;
        this.activeLanguage = newLang;
        this.getLanguageText(this.activeLanguage);
    };
    LanguageService.prototype.getActiveLanguage = function () {
        return localStorage.getItem(this.activeLanguageKey);
    };
    LanguageService.prototype.getLanguageText = function (lang) {
        var _this = this;
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", "./assets/languages/" + lang + "/text.json", false);
        rawFile.onreadystatechange = function () {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status == 0) {
                    _this.activeLanguageText = JSON.parse(rawFile.responseText);
                    _this.languageIsUpdated.next(Math.random());
                    _this.languageIsUpdated.next(null);
                    // console.log('lang', this.activeLanguage)
                }
            }
        };
        rawFile.send(null);
    };
    LanguageService.prototype.getString = function (value, innerVal) {
        if (this.activeLanguageText && this.activeLanguageText[value]) {
            if (innerVal) {
                if (this.activeLanguageText[value][innerVal]) {
                    return this.activeLanguageText[value][innerVal];
                }
                else {
                    return this.defaultLanguageText[value][innerVal];
                }
            }
            else {
                return this.activeLanguageText[value];
            }
        }
        if (innerVal) {
            return this.defaultLanguageText[value][innerVal];
        }
        return this.defaultLanguageText[value];
    };
    LanguageService.prototype.replaceVariables = function (string, vars) {
        vars.forEach(function (variable) {
            string = string.replace("{{" + Object.keys(variable)[0] + "}}", variable[Object.keys(variable)[0]]);
        });
        return string;
    };
    LanguageService.prototype.getStringWithVariables = function (vars, value, innerVal) {
        var string = this.getString(value, innerVal);
        return this.replaceVariables(string, vars);
    };
    LanguageService.prototype.getStringFirstCharCapital = function (value, innerVal) {
        var str = this.getString(value, innerVal);
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
