import { BehaviorSubject } from "rxjs";
import { formatCurrency } from "@angular/common";
import * as i0 from "@angular/core";
var CURRENCIES = {
    USD: {
        locale: 'en',
        currency: '$'
    },
    MXN: {
        locale: 'es-MX',
        currency: '$'
    }
};
var CurrencyService = /** @class */ (function () {
    function CurrencyService() {
        this.activeCurrencyKey = 'activeCurrency';
        this.defaultCurrency = 'USD';
        // requestNewLanguage = new BehaviorSubject(null);
        this.currencyIsUpdated = new BehaviorSubject(null);
    }
    CurrencyService.prototype.setCurrency = function (currency) {
        var newCurrency = currency ? currency : this.defaultCurrency;
        this.activeCurrency = newCurrency.toUpperCase();
        localStorage.setItem(this.activeCurrencyKey, this.activeCurrency);
        this.currencyIsUpdated.next(Math.random());
    };
    CurrencyService.prototype.getActiveCurrency = function () {
        return localStorage.getItem(this.activeCurrencyKey) ? localStorage.getItem(this.activeCurrencyKey) : 'USD';
    };
    CurrencyService.prototype.formatCurrency = function (amount) {
        var definedCurrency = CURRENCIES[this.getActiveCurrency()];
        if (definedCurrency) {
            return formatCurrency(+amount, definedCurrency.locale, definedCurrency.currency);
        }
        else {
            return formatCurrency(+amount, 'en', '') + ' ' + this.getActiveCurrency();
        }
    };
    CurrencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
