import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopupComponent} from "./popup/popup.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {MatSelectModule} from "@angular/material";
import {DemoMaterialModule} from "../material.module";
import {BalanceHeadingComponent} from "./balance-heading/balance-heading.component";
import {LanguageTranslatePipe} from "../modules/language/language-translate.pipe";
import {PopupWrapperComponent} from "./popup-wrapper/popup-wrapper.component";
import {CheckoutChargePopupComponent} from "./checkout-charge-popup/checkout-charge-popup.component";

const componentsList = [
    PopupComponent,
    LoaderComponent,
    BalanceHeadingComponent,
    LanguageTranslatePipe,
    PopupWrapperComponent,
    CheckoutChargePopupComponent
];

@NgModule({
    declarations: componentsList,
    imports: [
        CommonModule,
        FormsModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatSelectModule,
    ],
    providers: [
    ],
    exports: componentsList
})
export class SharedModule { }
