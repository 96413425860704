<div class="balance-heading">
    <div class="balance-heading__col">
        <div class="balance-heading__row" *ngIf="entries !== null">
            <div class="gl-text">{{'availableEntries' | languageTranslate}}</div>
<!--            <div class="gl-text">{{entries | currency : activeCurrency : 'symbol-narrow'}}</div>-->
            <div class="gl-text">{{formatCurrency(entries)}}</div>
        </div>
        <div class="balance-heading__row" *ngIf="send !== null">
            <div class="gl-text">{{'spendAccount' | languageTranslate}}</div>
<!--            <div class="gl-text">{{send | currency : activeCurrency : 'symbol-narrow'}}</div>-->
            <div class="gl-text">{{formatCurrency(send)}}</div>
        </div>
    </div>
    <div class="change-language">
        <div class="change-language__active" (click)="showLanguagesList = !showLanguagesList">
            <img src="../../../assets/images/flags/{{activeLanguage}}.png" alt="">
        </div>
        <div class="change-language__list" [class.is-active]="showLanguagesList">
            <ng-container *ngFor="let lang of languagesList">
                <div class="change-language__item"
                     *ngIf="!lang.active"
                     (click)="changeLanguage(lang.name)">
                    <img src="../../../assets/images/flags/{{lang.name}}.png" alt="">
                </div>
            </ng-container>

        </div>
    </div>

<!--    <div class="balance-heading__title">-->
<!--        {{title}}-->
<!--    </div>-->
</div>
