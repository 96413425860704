import {DOMAIN_API} from "./local_settings";
import {
    DOMAIN_DEMO_NAME, DOMAIN_DEMO_API
} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {environment} from "../../environments/environment";

let API = DOMAIN_API + '/ads/shop';
if(window.location.hostname == DOMAIN_DEMO_NAME){
    API = DOMAIN_DEMO_API + '/ads/shop';
}

if (environment.frontEndTest) {
    API = DOMAIN_API + '/pos';
}

export const AUTH_CONFIG: AuthConfig = {
    checkPhoneAndSendPin: API + '/shop/register/create',
    login: API + '/shop/register/verify',
};
if (environment.frontEndTest) {
    AUTH_CONFIG.checkPhoneAndSendPin = API + '/jsons/find/file?name=success';
    AUTH_CONFIG.login = API + '/jsons/find/file?name=pnSweepsSenetLogin';
}

export const HOME_CONFIG = {
    getPageInfo: API + '/info',
    getUserStatus:  API + '/user',
    payWithCashApp:  API + '/payment'
};
if (environment.frontEndTest) {
    HOME_CONFIG.getPageInfo = API + '/jsons/find/file?name=senetPromoPageInfo';
    HOME_CONFIG.getUserStatus = API + '/jsons/find/file?name=senetPromoUserInfo';
    HOME_CONFIG.payWithCashApp = API + '/jsons/find/file?name=senetPromoCashApp';
}
