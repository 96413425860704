import {Injectable} from '@angular/core';
import {COUNTRIES_DOMAINS} from "./country.config";

@Injectable({
  providedIn: 'root'
})
export class CountryService {


  constructor() {}

  detectCountry() {
    const host = window.location.host;
    // const host = 'mex.dkdk.com';
    // const host = 'fr.dkdk.com';
    const subdomain = host.split('.')[0];
      //const subdomain = 'mx';
    return COUNTRIES_DOMAINS[subdomain] ? COUNTRIES_DOMAINS[subdomain] : COUNTRIES_DOMAINS.default;
  }
}
