import {Injectable} from '@angular/core';
import * as defaultText from '../../../assets/languages/en/text.json'
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  activeLanguageKey = 'activeLanguage';
  defaultLanguage = 'en';
  defaultLanguageText = defaultText['default'];

  activeLanguage;
  activeLanguageText;

  requestNewLanguage = new BehaviorSubject(null);
  languageIsUpdated = new BehaviorSubject(null);

  constructor() {}

  changeLanguage(lang) {
    this.requestNewLanguage.next(lang);
  }

  setActiveLanguage(lang) {
    const newLang = lang ? lang : this.defaultLanguage;
    this.activeLanguage = newLang;
    this.getLanguageText(this.activeLanguage);
  }

  getActiveLanguage() {
    return localStorage.getItem(this.activeLanguageKey);
  }

  getLanguageText(lang) {
    const rawFile = new XMLHttpRequest();
    rawFile.open("GET", `./assets/languages/${lang}/text.json`, false);
    rawFile.onreadystatechange = () => {
      if(rawFile.readyState === 4)  {
        if(rawFile.status === 200 || rawFile.status == 0) {
          this.activeLanguageText = JSON.parse(rawFile.responseText);
          this.languageIsUpdated.next(Math.random());
          this.languageIsUpdated.next(null);
          // console.log('lang', this.activeLanguage)
        }
      }
    };
    rawFile.send(null);
  }

  getString(value, innerVal?) {
    if (this.activeLanguageText && this.activeLanguageText[value]) {
      if (innerVal) {
        if (this.activeLanguageText[value][innerVal]) {
          return this.activeLanguageText[value][innerVal]
        } else {
          return this.defaultLanguageText[value][innerVal];
        }
      } else {
        return this.activeLanguageText[value];
      }
    }
    if (innerVal) {
      return this.defaultLanguageText[value][innerVal];
    }
    return this.defaultLanguageText[value];
  }

  replaceVariables(string, vars: any) {
    vars.forEach((variable) => {
      string = string.replace(`{{${Object.keys(variable)[0]}}}`, variable[Object.keys(variable)[0]]);
    });
    return string;
  }

  getStringWithVariables(vars, value, innerVal?) {
    const string = this.getString(value, innerVal);
    return this.replaceVariables(string, vars);
  }

  getStringFirstCharCapital(value, innerVal?) {
    const str = this.getString(value, innerVal);
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
