<div class="login__forms" [class.is-blurred]="isTermsExpandedShown || showCashAppPopup">
    <div class="login__tab">
        <ng-container *ngIf="step === 1">
            <div class="page-block">
                <div class="gl-page-title gl-page-title--icon c-accent">
                    <svg>
                        <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                    </svg>
                    {{'createYourNFT' | languageTranslate}}
                </div>
                <span class="gl-subtitle gl-subtitle--opacity">
                {{'createYourNFTText' | languageTranslate}}
            </span>

                <div class="pic-generation__gen-images">
                    <ul class="brushes brushes--named brushes--no-padding">
                        <li class="brushes__item" *ngFor="let item of games">
                            <div class="brushes__item-name">{{item.name}}</div>
                            <img [src]="item.image_link"
                                 alt=""
                                 class="brushes__img">
                        </li>
                    </ul>
                </div>
            </div>

            <div class="page-block">
                <div class="gl-page-title gl-page-title--icon c-accent">
                    <svg>
                        <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                    </svg>
                    {{'depositTryLuckTitle' | languageTranslate}}
                </div>
                <span class="gl-subtitle gl-subtitle--opacity">
                {{'depositTryLuckText' | languageTranslate}}
            </span>

                <form [formGroup]="loginForm" class="login__form">
                    <div class="form-simple__item">
                        <input formControlName="promo"
                               placeholder="{{'promoCode' | languageTranslate}}"
                               [email]="true"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span style="opacity: .7; font-size: .8em">{{'promoCode' | languageTranslate}}</span>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="email"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
                               [email]="true"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div style="margin-bottom: 1rem; text-align: center">{{'or' | languageTranslate}}</div>

                    <div class="form-simple__item">
                        <input [mask]="phoneMask"
                               formControlName="phone"
                               placeholder="{{'phoneNumber' | languageTranslate}}"
                               [type]="'tel'"
                               (blur)="onPhoneBlur()"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div style="margin-bottom: 1rem; margin-top: -0.5rem; text-align: center; font-size: .8em; opacity: .7">
                        {{'weUseItToCreateAccount' | languageTranslate}}
                    </div>


                    <div class="checkout-btns checkout-btns--row" style="display: flex; justify-content: center;">
                        <button class="button"
                                (click)="onContinue()"
                                [disabled]="isContinueButtonDisabled()">
                            {{'continue' | languageTranslate}}
                        </button>
                    </div>
                </form>
            </div>
        </ng-container>

        <ng-container *ngIf="step === 2">
            <div class="page-block">
                <div class="gl-page-title gl-page-title--icon c-accent">
                    <svg>
                        <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                    </svg>
                    {{'depositStepTitle' | languageTranslate}}
                </div>
                <span class="gl-subtitle gl-subtitle--opacity">
                    {{'depositStepText' | languageTranslate}}
                </span>

                <form [formGroup]="keywordsForm" class="login__form">
                    <div class="form-simple__item">
                            <textarea name=""
                                      formControlName="textarea"
                                      id="keyWords"
                                      cols="30"
                                      rows="6"
                                      placeholder="Example: Scary looking clown"
                                      class="form-simple__input form-simple__textarea"></textarea>
                    </div>
                    <div class="nft-terms-agreement" style="margin-bottom: 2rem">
                        <input #termsAccept
                               id="termsAccept"
                               formControlName="terms"
                               class="nft-terms-agreement__input checkbox-primary"
                               type="checkbox">
                        <label class="nft-terms-agreement__label" for="termsAccept" style="cursor: pointer">
                            <!--                        I Accept-->
                            {{'iAgreeToTermsAndConditions' | languageTranslate}}
                        </label>
                    </div>
                    <div class="checkout-btns checkout-btns--row" style="display: flex; justify-content: center;">
                        <button class="button"
                                (click)="onShowCashAppPopup()"
                                [disabled]="isDepositButtonDisabled(termsAccept)">
                            {{'deposit' | languageTranslate}} $5
                        </button>
                    </div>
                </form>
            </div>
        </ng-container>



        <div class="login__footer">
            <button (click)="showCheckoutTerms()">
                Click to read Terms and Conditions
            </button>
        </div>
    </div>
</div>

<app-popup-wrapper [show]="isTermsExpandedShown" (onClose)="closeTermsExpanded()">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">
        <div class="nft-terms" [innerHTML]="terms"></div>
    </div>
</app-popup-wrapper>

<div [ngStyle]="{display: showCashAppPopup ? 'block' : 'none'}">
    <app-checkout-charge-popup *ngIf="cashAppPopupData"
                               [show]="showCashAppPopup"
                               [popupData]="cashAppPopupData.data"
                               (emitDepositConfirm)="onDepositConfirm($event)"
                               (close)="onCashAppPopupClose()">
    </app-checkout-charge-popup>
</div>
