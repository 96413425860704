import {Injectable} from '@angular/core';
import {PHONE_MASK} from "../config/country_phone_mask";
import {COUNTRIES_SHORT} from "../config/countries.config";

@Injectable({
    providedIn: 'root'
})
export class PhoneMaskService {
    PHONE_MASK_CONFIG = PHONE_MASK;
    country = localStorage.country ? localStorage.country : 'us';

    constructor() {
    }

    setPhoneMask() {

        if (this.country && this.PHONE_MASK_CONFIG[this.country]) {
            return this.PHONE_MASK_CONFIG[this.country];
        }
        console.log(this.PHONE_MASK_CONFIG, COUNTRIES_SHORT.usa)
        return this.PHONE_MASK_CONFIG[COUNTRIES_SHORT.usa];
    }

}
