import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {COUNTRIES_LANG_AVAILABLE} from "../../config/countries-lang.config";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";

@Component({
    selector: 'app-balance-heading',
    templateUrl: './balance-heading.component.html',
    styleUrls: ['./balance-heading.component.scss']
})
export class BalanceHeadingComponent implements OnInit {
    @Input() title: string;
    @Input() entries: number = null;
    @Input() send: number = null;

    COUNTRIES_LANG_AVAILABLE = COUNTRIES_LANG_AVAILABLE;
    showLanguagesList = false;
    languagesList = [];
    activeLanguage;

    // @Output('onClick') emitClick: EventEmitter<any> = new EventEmitter();
    @HostListener('window:click', ['$event.target'])
    onClick(el: HTMLElement){
        const menu = document.querySelector('.change-language');
        if (menu) {
            if (!menu.contains(el)) {
                this.showLanguagesList = false;
            }
        }
    }

    constructor(private languageService: LanguageService,
                private currencyService: CurrencyService) {
    }

    ngOnInit() {
        this.COUNTRIES_LANG_AVAILABLE.forEach(lang => {
            if (lang === this.languageService.getActiveLanguage()) {
                this.activeLanguage = lang;
                return
            }
            this.languagesList.push({
                name: lang
            });
        });

    }

    getAvailableCountriesList() {
        const list = [];
        this.COUNTRIES_LANG_AVAILABLE.forEach(lang => {
            let status = false;
            if (lang === this.languageService.getActiveLanguage()) {
               return
            }
            list.push({
                name: lang,
                active: status
            });
        });
        return list;
    }

    changeLanguage(lang) {
        this.showLanguagesList = false;
        this.languageService.changeLanguage(lang);
        setTimeout(() => {
            this.languagesList = [];
            this.COUNTRIES_LANG_AVAILABLE.forEach(lang => {
                if (lang === this.languageService.getActiveLanguage()) {
                    this.activeLanguage = lang;
                    return
                }
                this.languagesList.push({
                    name: lang
                });
            });
        });
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }
}
