import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from "./service/login.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {PopupComponent} from "./shared/popup/popup.component";
import {fromEvent} from "rxjs";
import {LanguageService} from "./modules/language/language.service";
import {CountryService} from "./modules/country/country.service";
import {COUNTRIES_LANG} from "./config/countries-lang.config";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    isConnected = true;
    noInternetConnection = false;

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private countryService: CountryService,
                private languageService: LanguageService,
                private popupComponent: PopupComponent) {
        fromEvent(window, 'online').subscribe((resp: any) => {
            this.isConnected = true;
            this.noInternetConnection = false;
            this.popupComponent.closePopup();
        });

        fromEvent(window, 'offline').subscribe((resp: any) => {
            this.isConnected = false;
            this.noInternetConnection = true;
            this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false})
        });

        this.languageService.requestNewLanguage.subscribe(val => {
            if (val) {
                this.changeLanguage(val);
            }
        })
    }

    ngOnInit() {
        if (this.isIos()) {
            document.body.classList.add('is-ios');
        }
        window.addEventListener('resize', this.appHeight);
        this.appHeight();
        document.body.classList.remove('is-blurred');
        this.detectLanguage();
    }

    ngOnDestroy() {}

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    detectLanguage() {
        const country = this.countryService.detectCountry();
        let defaultLang = '';
        let countryLang = '';

        Object.keys(COUNTRIES_LANG).forEach(lang => {
            COUNTRIES_LANG[lang].forEach(contr => {
                if (contr === 'default') {
                    defaultLang = lang;
                }
                if (contr === country) {
                    countryLang = lang;
                }
            });
        });

        const lang = countryLang ? countryLang : defaultLang;
        localStorage.setItem('country', country);

        this.changeLanguage(lang);
    }

    changeLanguage(lang) {
        this.languageService.setActiveLanguage(lang);
        localStorage.setItem('activeLanguage', lang);
    }
}


