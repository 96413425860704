import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SessionStorageService = /** @class */ (function () {
    function SessionStorageService(http) {
        this.http = http;
        this.storage = sessionStorage;
        this.userTokenKey = 'token';
        this.appLinkKey = 'appLink';
    }
    SessionStorageService.prototype.setUserToken = function (val) {
        this.storage.setItem(this.userTokenKey, val);
    };
    SessionStorageService.prototype.getUserToken = function () {
        return this.storage.getItem(this.userTokenKey);
    };
    SessionStorageService.prototype.removeUserToken = function () {
        this.storage.removeItem(this.userTokenKey);
    };
    SessionStorageService.prototype.setAppLink = function (val) {
        this.storage.setItem(this.appLinkKey, val);
    };
    SessionStorageService.prototype.getAppLink = function () {
        return this.storage.getItem(this.appLinkKey);
    };
    SessionStorageService.prototype.removeAppLink = function () {
        this.storage.removeItem(this.appLinkKey);
    };
    SessionStorageService.prototype.cleanStorage = function () {
        this.removeUserToken();
        this.removeAppLink();
    };
    SessionStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageService_Factory() { return new SessionStorageService(i0.ɵɵinject(i1.HttpClient)); }, token: SessionStorageService, providedIn: "root" });
    return SessionStorageService;
}());
export { SessionStorageService };
