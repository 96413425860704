/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance-heading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/language/language-translate.pipe";
import * as i3 from "../../modules/language/language.service";
import * as i4 from "@angular/common";
import * as i5 from "./balance-heading.component";
import * as i6 from "../../modules/currency/currency.service";
var styles_BalanceHeadingComponent = [i0.styles];
var RenderType_BalanceHeadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BalanceHeadingComponent, data: {} });
export { RenderType_BalanceHeadingComponent as RenderType_BalanceHeadingComponent };
function View_BalanceHeadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "balance-heading__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "gl-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "gl-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("availableEntries")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formatCurrency(_co.entries); _ck(_v, 5, 0, currVal_1); }); }
function View_BalanceHeadingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "balance-heading__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "gl-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "gl-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("spendAccount")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formatCurrency(_co.send); _ck(_v, 5, 0, currVal_1); }); }
function View_BalanceHeadingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "change-language__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLanguage(_v.parent.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "../../../assets/images/flags/", _v.parent.context.$implicit.name, ".png"); _ck(_v, 1, 0, currVal_0); }); }
function View_BalanceHeadingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceHeadingComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.active; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BalanceHeadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "balance-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "balance-heading__col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceHeadingComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceHeadingComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "change-language"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "change-language__active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showLanguagesList = !_co.showLanguagesList) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "change-language__list"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceHeadingComponent_3)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entries !== null); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.send !== null); _ck(_v, 5, 0, currVal_1); var currVal_4 = _co.languagesList; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "../../../assets/images/flags/", _co.activeLanguage, ".png"); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.showLanguagesList; _ck(_v, 9, 0, currVal_3); }); }
export function View_BalanceHeadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-balance-heading", [], null, [["window", "click"]], function (_v, en, $event) { var ad = true; if (("window:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BalanceHeadingComponent_0, RenderType_BalanceHeadingComponent)), i1.ɵdid(1, 114688, null, 0, i5.BalanceHeadingComponent, [i3.LanguageService, i6.CurrencyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BalanceHeadingComponentNgFactory = i1.ɵccf("app-balance-heading", i5.BalanceHeadingComponent, View_BalanceHeadingComponent_Host_0, { title: "title", entries: "entries", send: "send" }, {}, []);
export { BalanceHeadingComponentNgFactory as BalanceHeadingComponentNgFactory };
