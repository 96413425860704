import { PipeTransform } from "@angular/core";
var LanguageTranslatePipe = /** @class */ (function () {
    function LanguageTranslatePipe(languageService) {
        this.languageService = languageService;
        this.reservedInnerVariableName = "$innerVariable";
    }
    LanguageTranslatePipe.prototype.transform = function (value) {
        var _this = this;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (value) {
            var string_1 = this.languageService.getString(value);
            if (args.length) {
                args.forEach(function (arg) {
                    arg.forEach(function (argInner) {
                        Object.keys(argInner).forEach(function (argItem) {
                            if (argItem !== _this.reservedInnerVariableName) {
                                string_1 = string_1.replace("{{" + argItem + "}}", argInner[argItem]);
                            }
                            else {
                                string_1 = _this.languageService.getString(value, argInner[argItem]);
                            }
                        });
                        // if (Object.keys(argInner)[0] !== this.reservedInnerVariableName) {
                        //   string = string.replace(`{{${Object.keys(argInner)[0]}}}`, argInner[Object.keys(argInner)[0]]);
                        // } else {
                        //   string = this.languageService.getString(value, argInner[Object.keys(argInner)[0]]);
                        // }
                    });
                });
            }
            return string_1;
        }
        return value;
    };
    return LanguageTranslatePipe;
}());
export { LanguageTranslatePipe };
